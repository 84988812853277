var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"info","icon":"mdi-account-multiple-check","title":"Subscribers","value":_vm.counts.subscribers,"sub-icon":"mdi-arrow-right-circle","sub-text":"Manage subscribers","to":"/subscribers"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"primary","icon":"mdi-qrcode","title":"Coupons","value":_vm.counts.coupons,"sub-icon":"mdi-arrow-right-circle","sub-text":"Manage coupons","to":"/coupons"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"success","icon":"mdi-google-spreadsheet","title":"Sheets","value":_vm.counts.sheets,"sub-icon":"mdi-arrow-right-circle","sub-text":"Manage sheets","to":"/sheets"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"orange","icon":"mdi-account-group","title":"Users","value":_vm.counts.users,"sub-icon":"mdi-arrow-right-circle","sub-text":"Manage users","to":"/users"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('base-material-chart-card',{attrs:{"data":_vm.couponsChart.data,"options":_vm.couponsChart.options,"responsive-options":_vm.couponsChart.responsiveOptions,"color":"#E91E63","hover-reveal":"","type":"Bar"},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"light":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Change Date")])])]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-arrow-expand-all ")]),_c('router-link',{staticClass:"caption grey--text font-weight-light",attrs:{"to":"/coupons"}},[_vm._v("display all coupons")])]},proxy:true}])},[_c('h4',{staticClass:"card-title font-weight-light mt-2 ml-2"},[_vm._v(" Latest Coupons ")]),_c('p',{staticClass:"d-inline-flex font-weight-light ml-2 mt-1"},[_vm._v(" Last Added Coupons ")])])],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('base-material-chart-card',{attrs:{"data":_vm.subscribersChart.data,"options":_vm.subscribersChart.options,"hover-reveal":"","color":"info","type":"Line"},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"light":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Change Date")])])]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-arrow-expand-all ")]),_c('router-link',{staticClass:"caption grey--text font-weight-light",attrs:{"to":"/subscribers"}},[_vm._v("display all subscribers")])]},proxy:true}])},[_c('h3',{staticClass:"card-title font-weight-light mt-2 ml-2"},[_vm._v(" Latest Subscribers ")]),_c('p',{staticClass:"d-inline-flex font-weight-light ml-2 mt-1"},[_vm._v(" Last Added Subscribers ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-material-card',{staticClass:"px-5 py-3",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Subscribers ")]),_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" Last added subscribers ")])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.subscriberHeader,"items":_vm.subscribers,"hide-default-footer":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"warning"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Coupons ")]),_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" Last added coupons ")])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.couponHeader,"items":_vm.coupons,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"green","dark":"","outlined":""},on:{"click":function($event){return _vm.toggleCode(item)}}},[_vm._v(" "+_vm._s(item.show_code ? item.code : '####')+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }