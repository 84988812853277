<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-account-multiple-check"
          title="Subscribers"
          :value="counts.subscribers"
          sub-icon="mdi-arrow-right-circle"
          sub-text="Manage subscribers"
          to="/subscribers"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-qrcode"
          title="Coupons"
          :value="counts.coupons"
          sub-icon="mdi-arrow-right-circle"
          sub-text="Manage coupons"
          to="/coupons"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-google-spreadsheet"
          title="Sheets"
          :value="counts.sheets"
          sub-icon="mdi-arrow-right-circle"
          sub-text="Manage sheets"
          to="/sheets"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-account-group"
          title="Users"
          :value="counts.users"
          sub-icon="mdi-arrow-right-circle"
          sub-text="Manage users"
          to="/users"
        />
      </v-col>

      <v-col
        cols="12"
        lg="6"
      >
        <base-material-chart-card
          :data="couponsChart.data"
          :options="couponsChart.options"
          :responsive-options="couponsChart.responsiveOptions"
          color="#E91E63"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Latest Coupons
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Last Added Coupons
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-arrow-expand-all
            </v-icon>
            <router-link to="/coupons" class="caption grey--text font-weight-light">display all coupons</router-link>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="6"
      >
        <base-material-chart-card
          :data="subscribersChart.data"
          :options="subscribersChart.options"
          hover-reveal
          color="info"
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h3 class="card-title font-weight-light mt-2 ml-2">
            Latest Subscribers
          </h3>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Last Added Subscribers
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-arrow-expand-all
            </v-icon>
            <router-link to="/subscribers" class="caption grey--text font-weight-light">display all subscribers</router-link>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Subscribers
            </div>

            <div class="subtitle-1 font-weight-light">
              Last added subscribers
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="subscriberHeader"
              :items="subscribers"
              hide-default-footer
            />
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Coupons
            </div>

            <div class="subtitle-1 font-weight-light">
              Last added coupons
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="couponHeader"
              :items="coupons"
              hide-default-footer>
              <template v-slot:item.code="{ item }">
                <v-chip color="green" dark @click="toggleCode(item)" outlined>
                  {{ item.show_code ? item.code : '####'}}
                </v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardDashboard',
    data () {
      return {
        counts: [],
        statistics: [],
        subscribersChart: {
          data: {
            labels: [],
            series: [[]],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        couponsChart: {
          data: {
            labels: [],
            series: [[]],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 100,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        subscriberHeader: [
          {
            sortable: true,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: true,
            text: 'First Name',
            value: 'first_name',
          },
          {
            sortable: true,
            text: 'Last Name',
            value: 'last_name',
          },
          {
            sortable: true,
            text: 'Mobile',
            value: 'mobile',
          },
          {
            sortable: true,
            text: 'Activation Date',
            value: 'travel_date',
            align: 'right',
          },
        ],
        subscribers: [],
        couponHeader: [
          {
            sortable: true,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Coupon',
            value: 'code',
          },
          {
            sortable: true,
            text: 'Duration',
            value: 'duration.duration_text',
          },
          {
            sortable: true,
            text: 'Start Date',
            value: 'start_date',
          },
          {
            sortable: true,
            text: 'End Date',
            value: 'end_date',
            align: 'right',
          },
        ],
        coupons: [],
      }
    },
    created() {
      this.getCounts();
      this.getStatistics();
      this.getSubscribers();
      this.getCoupons();
    },
    methods: {
      getCounts() {
        this.$authApi.get('dashboard/counts').then(res => {
          this.counts = res.data.data.counts;
        })
      },
      getStatistics() {
        this.$authApi.get('dashboard/statistics').then(res => {
          this.statistics = res.data.data.statistics;
          this.statistics.subscribers.forEach(data => {
            this.subscribersChart.data.labels.push(data.date);
            this.subscribersChart.data.series[0].push(data.count);
          });
          this.statistics.coupons.forEach(data => {
            this.couponsChart.data.labels.push(data.date);
            this.couponsChart.data.series[0].push(data.count);
          });
        })
      },
      getSubscribers() {
        this.$authApi.get('dashboard/subscribers').then(res => {
          this.subscribers = res.data.data;
        })
      },
      getCoupons() {
        this.$authApi.get('dashboard/coupons').then(res => {
          let coupons = res.data.data;
          coupons.forEach(function (element) {
            element.show_code = false;
          });
          this.coupons = coupons;
        })
      },
      toggleCode(coupon){
        coupon.show_code = !coupon.show_code;
      }
    },
  }
</script>
